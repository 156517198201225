import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getImageList,
  downloadFile,
  downloadFileResponse,
  updateFile,
} from "Redux/Images/images.actions";
import Dropdown from "components/Dropdown";
import Datepicker from "components/DatePicker";
import { Button, Paper, TextField } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import DownloadIcon from "@material-ui/icons/SystemUpdateAlt";
import DownloadIconLight from "../../assets/icons/download_icon_light.svg";
import DownloadIconDark from "../../assets/icons/download_icon_dark.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import "./style.css";
import toast from "react-hot-toast";

function Census(props) {
  const [reviewed, setReviewed] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [currentFile, setCurrentFile] = useState("");
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [editRowId, setEditRowId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  let clinicId = localStorage.getItem("clinic_id");

  const [fileDetails, setFileDetails] = useState({
    imageId: "",
    fileName: "",
    note: "",
    reviewed: "",
  });

  const [headCells, setHeadCells] = useState([
    {
      id: "date",
      label: "Date",
    },
    {
      id: "type",
      label: "Type",
    },
    {
      id: "username",
      label: "Username",
    },
    {
      id: "mrn",
      label: "MRN",
    },
    {
      id: "patient",
      label: "Patient",
    },
    {
      id: "filename",
      label: "Filename",
    },
    {
      id: "notes",
      label: "Notes",
    },
    {
      id: "reviewed",
      label: "Reviewed",
    },
    {
      id: "actions",
      label: "Actions",
    },
  ]);

  const formatDate = (val) => {
    let date = new Date(val);
    let yr = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let d;
    let m;
    if (month < 10) {
      m = "0" + month.toString();
    } else {
      m = month.toString();
    }
    if (day < 10) {
      d = "0" + day.toString();
    } else {
      d = day.toString();
    }
    return yr + "-" + m + "-" + d;
  };

  useEffect(() => {
    props.getImageList({
      clinicId: clinicId,
      claimId: "",
      patientId: "",
      type: "",
      startDate: "",
      endDate: "",
      reviewed: "",
      limit: rowsPerPage,
      start: page,
    });
  }, [page, rowsPerPage]);

  const getFilteredRows = () => {
    console.log(formatDate(startDate));
    props.getImageList({
      clinicId: clinicId,
      claimId: "",
      patientId: "",
      type: "",
      startDate: startDate ? formatDate(startDate) : "",
      endDate: startDate ? formatDate(startDate) : "",
      reviewed: reviewed === 1 ? 1 : reviewed === 0 ? 0 : "",
      limit: rowsPerPage,
      start: 0,
    });
  };

  useEffect(() => {
    if (
      props.ImagesData &&
      props.ImagesData.imageListResponse &&
      props.ImagesData.imageListResponse.response
    ) {
      let data = props.ImagesData.imageListResponse.response.data;
      setRows(data.results);
      setTotalRows(data.totalRecords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ImagesData.imageListResponse]);

  const handleDownload = (id, name, imageType, fileType) => {
    props.downloadFile({
      fileId: id,
      fileName: name,
      imageType: imageType,
      fileType: fileType,
    });
    setCurrentFile(name);
  };

  const downloadFiles = (content, fileName, contentType) => {
    if (!contentType) contentType = "application/octet-stream";
    var a = document.createElement("a");
    var blob = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    blob = null;
  };

  useEffect(() => {
    if (props.ImagesData && props.ImagesData.downloadFileRes) {
      let blobData = props.ImagesData.downloadFileRes.response;
      let fileName = currentFile;
      let fileType = currentFile.split(".")[1];
      downloadFiles(blobData, fileName, fileType);
      props.downloadFileResponse(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ImagesData.downloadFileRes]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleEditFile = (row) => {
    if (editRowId === row.imageId) {
      props.updateFile([
        {
          ...fileDetails,
          imageId: row.imageId,
        },
      ]);
    } else {
      setEditRowId(row.imageId);
      setFileDetails({
        imageId: row.imageId,
        fileName: row.fileName,
        note: row.note,
        reviewed: row.reviewed,
      });
    }
  };

  useEffect(() => {
    if (
      props.ImagesData &&
      props.ImagesData.updateFileRes &&
      props.ImagesData.updateFileRes.response
    ) {
      if (props.ImagesData.updateFileRes.response.responseCode === 0) {
        setEditRowId("");
        props.getImageList({
          clinicId: clinicId,
          claimId: "",
          patientId: "",
          type: "",
          startDate: "",
          endDate: "",
          reviewed: "",
          limit: rowsPerPage,
          start: page,
        });
        toast.success("File details updated");
      }
    }
  }, [props.ImagesData.updateFileRes.response]);

  return (
    <div className="census_main_container">
      <div className="census_filter_container">
        <div className="census_pagination_container">
          <TablePagination
            component="div"
            rowsPerPageOptions={[50, 100, 200]}
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <div className="census_filters">
          <div className="census_filter_dropdown">
            <Dropdown
              label="Reviewed"
              value={reviewed}
              options={[
                { name: "All", value: 2 },
                { name: "Yes", value: 1 },
                { name: "No", value: 0 },
              ]}
              onChange={(e) => setReviewed(e.target.value)}
            />
          </div>
          <div className="census_filter_datePicker">
            <Datepicker
              label="Date"
              value={startDate}
              onChange={(e) => setStartDate(e)}
            />
          </div>
          <div className="census_filter_button">
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={getFilteredRows}
            >
              Filter
            </Button>
          </div>
        </div>
      </div>
      <div className="census_table_main_container">
        <TableContainer elevation={0} className="census_table_container">
          <Table size="small" stickyHeader>
            <TableHead className="census_table_head">
              <TableRow>
                <TableCell className="census_table_head_cell"></TableCell>
                {headCells.map((x) => (
                  <TableCell className="census_table_head_cell" key={x.id}>
                    {x.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? (
                rows.map((x, i) => (
                  <TableRow key={i}>
                    <TableCell className="census_table_body_cell"></TableCell>
                    <TableCell className="census_table_body_cell">
                      {x.imageDate}
                    </TableCell>
                    <TableCell className="census_table_body_cell">
                      {x.imageType}
                    </TableCell>
                    <TableCell className="census_table_body_cell">
                      {x.userName}
                    </TableCell>
                    <TableCell className="census_table_body_cell">
                      {x.mrn}
                    </TableCell>
                    <TableCell className="census_table_body_cell">
                      {x.patientFName + " " + x.patientLName}
                    </TableCell>
                    <TableCell className="census_table_body_cell">
                      {editRowId === x.imageId ? (
                        <TextField
                          value={fileDetails.fileName}
                          onChange={(e) =>
                            setFileDetails({
                              ...fileDetails,
                              fileName: e.target.value,
                            })
                          }
                        />
                      ) : (
                        <>{x.fileName}</>
                      )}
                    </TableCell>
                    <TableCell className="census_table_body_cell">
                      {editRowId === x.imageId ? (
                        <TextField
                          value={fileDetails.note}
                          onChange={(e) =>
                            setFileDetails({
                              ...fileDetails,
                              note: e.target.value,
                            })
                          }
                        />
                      ) : (
                        <>{x.note}</>
                      )}
                    </TableCell>
                    <TableCell className="census_table_body_cell">
                      {editRowId === x.imageId ? (
                        <Dropdown
                          value={fileDetails.reviewed}
                          options={[
                            { name: "Yes", value: "1" },
                            { name: "No", value: "0" },
                          ]}
                          onChange={(e) =>
                            setFileDetails({
                              ...fileDetails,
                              reviewed: e.target.value,
                            })
                          }
                        />
                      ) : (
                        <>{x.reviewed === 1 ? "Yes" : "No"}</>
                      )}
                    </TableCell>
                    <TableCell className="census_table_body_cell actions">
                      <div className="view_button">View</div>
                      <div
                        className="view_button"
                        onClick={() => handleEditFile(x)}
                      >
                        {editRowId === x.imageId ? "Save" : "Edit"}
                      </div>
                      <div
                        className="image_download_icon_container"
                        onClick={() =>
                          handleDownload(
                            x.imageId,
                            x.fileName,
                            x.imageTypeId,
                            x.fileType
                          )
                        }
                      >
                        <img
                          className="image_download_icon"
                          src={DownloadIconLight}
                          alt="download"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ImagesData: state.images,
});

const mapDispatchToProps = (dispatch) => ({
  getImageList: (values) => dispatch(getImageList(values)),
  downloadFile: (values) => dispatch(downloadFile(values)),
  downloadFileResponse: (values) => dispatch(downloadFileResponse(values)),
  updateFile: (values) => dispatch(updateFile(values)),
});

Census.propTypes = {
  ImagesData: PropTypes.object,
  getImageList: PropTypes.func,
  downloadFile: PropTypes.func,
  downloadFileResponse: PropTypes.func,
  updateFile: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Census);
