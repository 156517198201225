import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { deleteFavouriteIcd } from "Redux/Rounding/rounding.actions";
import "./style.css";

function FavIcdDialog(props) {
  let providerId = parseInt(localStorage.getItem("providerId"));
  const [favIcdList, setFavIcdList] = useState([]);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    if (
      props.roundingData &&
      props.roundingData.favouriteIcdListRes &&
      props.roundingData.favouriteIcdListRes.response
    ) {
      if (props.roundingData.favouriteIcdListRes.response.responseCode === 0) {
        let data = props.roundingData.favouriteIcdListRes.response.data;
        setFavIcdList(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roundingData.favouriteIcdListRes.response]);

  const handleDelete = (x, i) => {
    setItemToDelete(x);
    setDeleteConfirmationOpen(true);
  };

  const confirmDelete = () => {
    if (itemToDelete) {
      props.deleteFavouriteIcd({
        providerId: providerId,
        icdCode: itemToDelete.icdCode,
      });
      setDeleteConfirmationOpen(false);
      const updatedList = favIcdList.filter(
        (item) => item.icdCode !== itemToDelete.icdCode
      );
      setFavIcdList(updatedList);
    }
  };

  const cancelDelete = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleCheckbox = (e, i) => {
    let r = favIcdList;
    r[i] = { ...r[i], isSelected: e.target.checked };
    setFavIcdList([...r]);
    const a = favIcdList.filter((item) => item.isSelected);
    props.onSelectionChange(a);
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose}>
        <div className="icd_favourites_container">
          <div
            style={{
              // fontSize: "18px",
              // fontWeight: "600",
              // paddingBottom: "1.5rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "600",
                paddingBottom: "1.5rem",
              }}
            >
              ICD Favorites
            </Typography>
            {/* <CloseIcon
              onClick={() => props.onClose()}
              style={{ cursor: "pointer" }}
            /> */}
            <div className="button_container1">
              <Button
                onClick={() => props.onClose()}
                variant="outlined"
                color="primary"
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>{" "}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ color: "white" }}
                onClick={() => props.onClose()}
              >
                Submit
              </Button>
            </div>
          </div>
          <div className="favIcdListContainer">
            {favIcdList.length ? (
              favIcdList.map((x, i) => (
                <div className="favIcdList">
                  <div className="favIcdCheckbox">
                    <Checkbox
                      color="primary"
                      size="small"
                      onChange={(e) => handleCheckbox(e, i)}
                    />
                  </div>
                  <div className="favIcdCode">{x.icdCode}</div>
                  <div className="favIcdDec"> {x.icdDescShort} </div>
                  <div className="favIcdIcons">
                    {/* <EditRounded /> */}
                    <CloseIcon onClick={(e) => handleDelete(x, i)} />
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          {/* <div className="button_container">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ color: "white", marginRight: "10px" }}
            >
              Submit
            </Button>
            <Button
              onClick={() => props.onClose()}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </div> */}
        </div>
      </Dialog>

      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={cancelDelete}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            No
          </Button>
          <Button onClick={confirmDelete} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  roundingData: state.rounding,
});

const mapDispatchToProps = (dispatch) => ({
  deleteFavouriteIcd: (values) => dispatch(deleteFavouriteIcd(values)),
});
FavIcdDialog.propTypes = {
  deleteFavouriteIcd: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FavIcdDialog);
