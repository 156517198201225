import {
  IMAGE_LIST_RES,
  DOWNLOAD_FILE_RES,
  UPDATE_FILE_RES,
} from "./images.types";

const INITIAL_STATE = {
  imageListResponse: [],
  downloadFileRes: null,
  updateFileRes: {},
};

const imagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IMAGE_LIST_RES: {
      return { ...state, ...{ imageListResponse: action.payload } };
    }
    case DOWNLOAD_FILE_RES: {
      return { ...state, ...{ downloadFileRes: action.payload } };
    }
    case UPDATE_FILE_RES: {
      return { ...state, ...{ updateFileRes: action.payload } };
    }
    default:
      return state;
  }
};

export default imagesReducer;
