import {
  GET_ROUNDING_LIST_RES,
  GET_ROUNDING_CACHE_RES,
  ADD_ROUNDING_RES,
  GET_ROUNDING_DETAILS_RES,
  SAVE_ROUNDING_DETAILS_RES,
  EDIT_ROUNDING_RES,
  SEARCH_PATIENT_RES,
  SAVE_PATIENT_RES,
  DELETE_PATIENT_RES,
  DISCHARGE_PATIENT_RES,
  SEARCH_ICD_RES,
  SAVE_ICD_RES,
  SEARCH_CPT_RES,
  SAVE_CPT_RES,
  SAVE_FAVOURITE_ICD_RES,
  FAVOURITE_ICD_LIST_RES,
  DELETE_FAVOURITE_ICD_RES,
  FAVOURITE_CPT_LIST_RES,
  SAVE_FAVOURITE_CPT_RES,
  DELETE_FAVOURITE_CPT_RES
} from "./rounding.types";

const INITIAL_STATE = {
  roundingListRes: [],
  roundingCacheRes: {},
  addRoundingRes: {},
  roundingDetailsRes: {},
  saveRoundingDetailsRes: {},
  editRoundingRes: {},
  searchPatientResponse: [],
  savePatientResponse: {},
  deletePatientRes: {},
  dischargePatientRes: {},
  searchIcdRes: {},
  saveIcdRes: {},
  searchCptRes: {},
  saveCptRes: {},
  saveFavouriteIcdRes: {},
  favouriteIcdListRes: {},
  deleteFavouriteIcdRes: {},
  favouriteCptListRes: {},
  saveFavouriteCptRes: {},
  deleteFavouriteCptRes: {}
};

const roundingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ROUNDING_LIST_RES: {
      return { ...state, ...{ roundingListRes: action.payload } };
    }
    case GET_ROUNDING_CACHE_RES: {
      return { ...state, ...{ roundingCacheRes: action.payload } };
    }
    case ADD_ROUNDING_RES: {
      return { ...state, ...{ addRoundingRes: action.payload } };
    }
    case GET_ROUNDING_DETAILS_RES: {
      return { ...state, ...{ roundingDetailsRes: action.payload } };
    }
    case SAVE_ROUNDING_DETAILS_RES: {
      return { ...state, ...{ saveRoundingDetailsRes: action.payload } };
    }
    case EDIT_ROUNDING_RES: {
      return { ...state, ...{ editRoundingRes: action.payload } };
    }
    case SEARCH_PATIENT_RES: {
      return { ...state, ...{ searchPatientResponse: action.payload } };
    }
    case SAVE_PATIENT_RES: {
      return { ...state, ...{ savePatientResponse: action.payload } };
    }
    case DELETE_PATIENT_RES: {
      return { ...state, ...{ deletePatientRes: action.payload } };
    }
    case DISCHARGE_PATIENT_RES: {
      return { ...state, ...{ dischargePatientRes: action.payload } };
    }
    case SEARCH_ICD_RES: {
      return { ...state, ...{ searchIcdRes: action.payload } };
    }
    case SAVE_ICD_RES: {
      return { ...state, ...{ saveIcdRes: action.payload } };
    }
    case SEARCH_CPT_RES: {
      return { ...state, ...{ searchCptRes: action.payload } };
    }
    case SAVE_CPT_RES: {
      return { ...state, ...{ saveCptRes: action.payload } };
    }
    case SAVE_FAVOURITE_ICD_RES: {
      return { ...state, ...{ saveFavouriteIcdRes: action.payload } }
    }
    case FAVOURITE_ICD_LIST_RES: {
      return { ...state, ...{ favouriteIcdListRes: action.payload } }
    }
    case DELETE_FAVOURITE_ICD_RES: {
      return { ...state, ...{ deleteFavouriteIcdRes: action.payload } }
    }
    case FAVOURITE_CPT_LIST_RES: {
      return { ...state, ...{ favouriteCptListRes: action.payload } }
    }
    case SAVE_FAVOURITE_CPT_RES: {
      return { ...state, ...{ saveFavouriteCptRes: action.payload } }
    }
    case DELETE_FAVOURITE_CPT_RES: {
      return { ...state, ...{ deleteFavouriteCptRes: action.payload } }
    }
    default:
      return state;
  }
};

export default roundingReducer;
