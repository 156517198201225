import { LOGIN_RES, LOGOUT_RES } from "./login.types";

/**
 * @fileOverview Manages the response of action
 * @author  Merina Joy <merina@tensaw.email>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  loginResponse: {},
  logoutResponse: {},
};

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{loginResponse: {}, login: boolean, status: boolean}|{loginResponse: *, login: boolean, status: boolean}|{loginResponse: {}, login: boolean, status: *}}
 */

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_RES: {
      return { ...state, ...{ loginResponse: action.payload } };
    }
    case LOGOUT_RES: {
      return { ...state, ...{ logoutResponse: action.payload } };
    }
    default:
      return state;
  }
};

export default loginReducer;
