import { ofType } from "redux-observable";
import { switchMap, map, takeUntil } from "rxjs/operators";
// import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from "Utils";
import environment from "../../environments/environment";
import {
  GET_ROUNDING_LIST,
  GET_ROUNDING_CACHE,
  ADD_ROUNDING,
  GET_ROUNDING_DETAILS,
  SAVE_ROUNDING_DETAILS,
  EDIT_ROUNDING,
  SEARCH_PATIENT,
  SAVE_PATIENT,
  DELETE_PATIENT,
  DISCHARGE_PATIENT,
  SEARCH_ICD,
  SAVE_ICD,
  SEARCH_CPT,
  SAVE_CPT,
  REQUEST_CANCEL,
  SAVE_FAVOURITE_ICD,
  FAVOURITE_ICD_LIST,
  DELETE_FAVOURITE_ICD,
  FAVOURITE_CPT_LIST,
  SAVE_FAVOURITE_CPT,
  DELETE_FAVOURITE_CPT
} from "./rounding.types";
import {
  getRoundingListRes,
  getRoundingCacheRes,
  addRoundingRes,
  getRoundingDetailsRes,
  saveRoundingDetailsRes,
  editRoundingRes,
  searchPatientResponse,
  savePatientResponse,
  deletePatientResponse,
  dischargePatientResponse,
  searchIcdResponse,
  saveIcdResponse,
  searchCptResponse,
  saveCptResponse,
  saveFavouriteIcdResponse,
  favouriteIcdListResponse,
  deleteFavouriteIcdResponse,
  favouriteCptListResponse,
  saveFavouriteCptResponse,
  deleteFavouriteCptResponse
} from "./rounding.actions";
// import { Apis } from 'Redux/APIs'
const { clinicBaseUrl } = environment;
const RoundingEpic = {};

RoundingEpic.getRoundingList = (action$) =>
  action$.pipe(
    ofType(GET_ROUNDING_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/rounding/list/all?providerIds=${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getRoundingListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.getRoundingCache = (action$) =>
  action$.pipe(
    ofType(GET_ROUNDING_CACHE),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-clinic-service/v1/user/logged-in/info/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getRoundingCacheRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.addRounding = (action$) =>
  action$.pipe(
    ofType(ADD_ROUNDING),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "application-type": 5,
          "Content-Type": "application/json",
        },
        url: `${clinicBaseUrl}/trillium-provider-service/v1/rounding/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => addRoundingRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.getRoundingDetails = (action$) =>
  action$.pipe(
    ofType(GET_ROUNDING_DETAILS),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/rounding/${action.payload.roundingId}?dos=${action.payload.dos}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getRoundingDetailsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.saveRoundingDetails = (action$) =>
  action$.pipe(
    ofType(SAVE_ROUNDING_DETAILS),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "application-type": 5,
          "Content-Type": "application/json",
        },
        url: `${clinicBaseUrl}/trillium-provider-service/v1/visit/charge/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveRoundingDetailsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.editRounding = (action$) =>
  action$.pipe(
    ofType(EDIT_ROUNDING),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "application-type": 5,
          "Content-Type": "application/json",
        },
        url: `${clinicBaseUrl}/trillium-provider-service/v1/rounding/${action.payload.roundingId}`,
        method: "PUT",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => editRoundingRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.searchPatient = (action$) =>
  action$.pipe(
    ofType(SEARCH_PATIENT),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-clinic-service/v1/patient/search?firstName=${action.payload.firstName}&lastName=${action.payload.lastName}&clinicId=${action.payload.clinicId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => searchPatientResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.savePatient = (action$) =>
  action$.pipe(
    ofType(SAVE_PATIENT),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-clinic-service/v1/patient/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => savePatientResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.deletePatient = (action$) =>
  action$.pipe(
    ofType(DELETE_PATIENT),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/rounding/${action.payload}`,
        method: "DELETE",
      }).pipe(
        map(
          (response) => deletePatientResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.dischargePatient = (action$) =>
  action$.pipe(
    ofType(DISCHARGE_PATIENT),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/rounding/dischargePatient/${action.payload.roundingId}`,
        method: "PUT",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => dischargePatientResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.searchIcd = (action$) =>
  action$.pipe(
    ofType(SEARCH_ICD),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-clinic-service/v1/icd/icdcode/${action.payload.s}?clinicId=${action.payload.cid}`,
        method: "GET",
      }).pipe(
        map(
          (response) => searchIcdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.saveIcd = (action$) =>
  action$.pipe(
    ofType(SAVE_ICD),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "application-type": 5,
          "Content-Type": "application/json",
        },
        url: `${clinicBaseUrl}/trillium-provider-service/v1/icd/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveIcdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.searchCpt = (action$) =>
  action$.pipe(
    ofType(SEARCH_CPT),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-clinic-service/v1/cliniccpt/cptcode/${action.payload.cptCode}?clinicId=${action.payload.clinicId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => searchCptResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.saveCpt = (action$) =>
  action$.pipe(
    ofType(SAVE_CPT),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "application-type": 5,
          "Content-Type": "application/json",
        },
        url: `${clinicBaseUrl}/trillium-provider-service/v1/cpt/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveCptResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.saveFavouriteIcd = (action$) =>
  action$.pipe(
    ofType(SAVE_FAVOURITE_ICD),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/provider/favourite/icd/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveFavouriteIcdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.favouriteIcdList = (action$) =>
  action$.pipe(
    ofType(FAVOURITE_ICD_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/provider/favourite/icd/list/all/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => favouriteIcdListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.deleteFavouriteIcd = (action$) =>
  action$.pipe(
    ofType(DELETE_FAVOURITE_ICD),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/provider/favourite/icd/${action.payload.providerId}?icdCode=${action.payload.icdCode}`,
        method: "DELETE",
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => deleteFavouriteIcdResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.favouriteCptList = (action$) =>
  action$.pipe(
    ofType(FAVOURITE_CPT_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/provider/favourite/cpt/list/all/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => favouriteCptListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.saveFavouriteCpt = (action$) =>
  action$.pipe(
    ofType(SAVE_FAVOURITE_CPT),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/provider/favourite/cpt/`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveFavouriteCptResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

RoundingEpic.deleteFavouriteCpt = (action$) =>
  action$.pipe(
    ofType(DELETE_FAVOURITE_CPT),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-provider-service/v1/provider/favourite/cpt/${action.payload.providerId}?cptCode=${action.payload.cptCode}`,
        method: "DELETE",
        // body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => deleteFavouriteCptResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

export default RoundingEpic;
