import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import Login from "Containers/Login";
import Header from "Containers/Header";
import Rounding from "Containers/Rounding";
import Census from "Containers/Census";
import "./GlobalStyles.css";

function App() {
  const [pathName, setPathName] = useState();

  useEffect(() => {
    let p = window.location.href.split("/")[3];
    setPathName(p.toString());
  }, []);

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <BrowserRouter>
        {pathName === "login" || pathName === "" ? null : <Header />}
        <React.Suspense fallback={"loading"}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/images" component={Census} />
            <Route path="/rounding" component={Rounding} />
            {/* <Route path="/dashboard" component={Dashboard} /> */}
            <Redirect from="/" to="/login" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </div>
  );
}

// className="header center p-xs sticky"

export default App;
