import {
  IMAGE_LIST,
  IMAGE_LIST_RES,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_RES,
  UPDATE_FILE,
  UPDATE_FILE_RES,
} from "./images.types";

export const getImageList = (data) => ({
  type: IMAGE_LIST,
  payload: data,
});

export const getImageListResponse = (data) => ({
  type: IMAGE_LIST_RES,
  payload: data,
});

export const downloadFile = (data) => ({
  type: DOWNLOAD_FILE,
  payload: data,
});

export const downloadFileResponse = (data) => ({
  type: DOWNLOAD_FILE_RES,
  payload: data,
});

export const updateFile = (data) => ({
  type: UPDATE_FILE,
  payload: data,
});

export const updateFileResponse = (data) => ({
  type: UPDATE_FILE_RES,
  payload: data,
});