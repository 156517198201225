/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Merina Joy <merina@tensaw.email>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */

export const REQUEST_CANCEL = "REQUEST_CANCEL";
export const LOGIN = "LOGIN";
export const LOGIN_RES = "LOGIN_RES";
export const LOGOUT = "LOGOUT";
export const LOGOUT_RES = "LOGOUT_RES";
