import { combineReducers } from "redux";
import loginReducer from "./Login/login.reducer";
import imagesReducer from "./Images/images.reducer";
import roundingReducer from "./Rounding/rounding.reducer";

/** rootReducer
 * @fileOverview Combine  all reducer action
 * @author  Merina Joy <merina@tensaw.email>
 */

const rootReducer = combineReducers({
  login: loginReducer,
  images: imagesReducer,
  rounding: roundingReducer,
});

export default rootReducer;
