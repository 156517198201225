/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Merina Joy <merina@tensaw.email>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */

export const REQUEST_CANCEL = "REQUEST_CANCEL";
export const IMAGE_LIST = "IMAGE_LIST";
export const IMAGE_LIST_RES = "IMAGE_LIST_RES";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const DOWNLOAD_FILE_RES = "DOWNLOAD_FILE_RES";
export const UPDATE_FILE = "UPDATE_FILE";
export const UPDATE_FILE_RES = "UPDATE_FILE_RES";
