import {
  GET_ROUNDING_LIST,
  GET_ROUNDING_LIST_RES,
  GET_ROUNDING_CACHE,
  GET_ROUNDING_CACHE_RES,
  ADD_ROUNDING,
  ADD_ROUNDING_RES,
  GET_ROUNDING_DETAILS,
  GET_ROUNDING_DETAILS_RES,
  SAVE_ROUNDING_DETAILS,
  SAVE_ROUNDING_DETAILS_RES,
  EDIT_ROUNDING,
  EDIT_ROUNDING_RES,
  SEARCH_PATIENT,
  SEARCH_PATIENT_RES,
  SAVE_PATIENT,
  SAVE_PATIENT_RES,
  DELETE_PATIENT,
  DELETE_PATIENT_RES,
  DISCHARGE_PATIENT,
  DISCHARGE_PATIENT_RES,
  SEARCH_ICD,
  SEARCH_ICD_RES,
  SAVE_ICD,
  SAVE_ICD_RES,
  SEARCH_CPT,
  SEARCH_CPT_RES,
  SAVE_CPT,
  SAVE_CPT_RES,
  SAVE_FAVOURITE_ICD,
  SAVE_FAVOURITE_ICD_RES,
  FAVOURITE_ICD_LIST,
  FAVOURITE_ICD_LIST_RES,
  DELETE_FAVOURITE_ICD,
  DELETE_FAVOURITE_ICD_RES,
  FAVOURITE_CPT_LIST,
  FAVOURITE_CPT_LIST_RES,
  SAVE_FAVOURITE_CPT,
  SAVE_FAVOURITE_CPT_RES,
  DELETE_FAVOURITE_CPT,
  DELETE_FAVOURITE_CPT_RES
} from "./rounding.types";

export const getRoundingList = (data) => ({
  type: GET_ROUNDING_LIST,
  payload: data,
});

export const getRoundingListRes = (data) => ({
  type: GET_ROUNDING_LIST_RES,
  payload: data,
});

export const getRoundingCache = (data) => ({
  type: GET_ROUNDING_CACHE,
  payload: data,
});

export const getRoundingCacheRes = (data) => ({
  type: GET_ROUNDING_CACHE_RES,
  payload: data,
});

export const addRounding = (data) => ({
  type: ADD_ROUNDING,
  payload: data,
});

export const addRoundingRes = (data) => ({
  type: ADD_ROUNDING_RES,
  payload: data,
});

export const getRoundingDetails = (data) => ({
  type: GET_ROUNDING_DETAILS,
  payload: data,
});

export const getRoundingDetailsRes = (data) => ({
  type: GET_ROUNDING_DETAILS_RES,
  payload: data,
});

export const saveRoundingDetails = (data) => ({
  type: SAVE_ROUNDING_DETAILS,
  payload: data,
});

export const saveRoundingDetailsRes = (data) => ({
  type: SAVE_ROUNDING_DETAILS_RES,
  payload: data,
});

export const editRounding = (data) => ({
  type: EDIT_ROUNDING,
  payload: data,
});

export const editRoundingRes = (data) => ({
  type: EDIT_ROUNDING_RES,
  payload: data,
});

export const searchPatient = (data) => ({
  type: SEARCH_PATIENT,
  payload: data,
});

export const searchPatientResponse = (res) => ({
  type: SEARCH_PATIENT_RES,
  payload: res,
});

export const savePatient = (data) => ({
  type: SAVE_PATIENT,
  payload: data,
});

export const savePatientResponse = (res) => ({
  type: SAVE_PATIENT_RES,
  payload: res,
});

export const deletePatient = (data) => ({
  type: DELETE_PATIENT,
  payload: data,
});

export const deletePatientResponse = (res) => ({
  type: DELETE_PATIENT_RES,
  payload: res,
});

export const dischargePatient = (data) => ({
  type: DISCHARGE_PATIENT,
  payload: data,
});

export const dischargePatientResponse = (res) => ({
  type: DISCHARGE_PATIENT_RES,
  payload: res,
});

export const searchIcd = (data) => ({
  type: SEARCH_ICD,
  payload: data,
});

export const searchIcdResponse = (res) => ({
  type: SEARCH_ICD_RES,
  payload: res,
});

export const saveIcd = (data) => ({
  type: SAVE_ICD,
  payload: data,
});

export const saveIcdResponse = (res) => ({
  type: SAVE_ICD_RES,
  payload: res,
});

export const searchCpt = (data) => ({
  type: SEARCH_CPT,
  payload: data,
});

export const searchCptResponse = (res) => ({
  type: SEARCH_CPT_RES,
  payload: res,
});

export const saveCpt = (data) => ({
  type: SAVE_CPT,
  payload: data,
});

export const saveCptResponse = (res) => ({
  type: SAVE_CPT_RES,
  payload: res,
});

export const saveFavouriteIcd = (data) => ({
  type: SAVE_FAVOURITE_ICD,
  payload: data
})

export const saveFavouriteIcdResponse = (res) => ({
  type: SAVE_FAVOURITE_ICD_RES,
  payload: res
})

export const favouriteIcdList = (data) => ({
  type: FAVOURITE_ICD_LIST,
  payload: data
})

export const favouriteIcdListResponse = (res) => ({
  type: FAVOURITE_ICD_LIST_RES,
  payload: res
})

export const deleteFavouriteIcd = (data) => ({
  type: DELETE_FAVOURITE_ICD,
  payload: data
})

export const deleteFavouriteIcdResponse = (res) => ({
  type: DELETE_FAVOURITE_ICD_RES,
  payload: res
})

export const favouriteCptList = (data) => ({
  type: FAVOURITE_CPT_LIST,
  payload: data
})

export const favouriteCptListResponse = (res) => ({
  type: FAVOURITE_CPT_LIST_RES,
  payload: res
})

export const saveFavouriteCpt = (data) => ({
  type: SAVE_FAVOURITE_CPT,
  payload: data
})

export const saveFavouriteCptResponse = (res) => ({
  type: SAVE_FAVOURITE_CPT_RES,
  payload: res
})

export const deleteFavouriteCpt = (data) => ({
  type: DELETE_FAVOURITE_CPT,
  payload: data
})

export const deleteFavouriteCptResponse = (res) => ({
  type: DELETE_FAVOURITE_CPT_RES,
  payload: res
})