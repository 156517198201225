import { LOGIN, LOGIN_RES, LOGOUT, LOGOUT_RES } from "./login.types";

/**
 * @fileOverview Manages the action w.r.t types in the redux
 * @author Merina Joy <merina@tensaw.email>

 * return(
 type and payload
 * )
 * @example export const actionName = (params) => ({
  type: Type of the action from login.type.js,
  payload: object - contains params,
})
 */

/**
 * login-Action w.r.t LOGIN(type) in the redux
 * @description - Action used to call the clinic save API
 * @param logData
 * @returns {{payload: *, type: string}}
 */
export const login = (data) => ({
  type: LOGIN,
  payload: data,
});
/**
 * loginResponse - Action w.r.t LOGIN_RES(type) in the redux
 * @description - Action used to get the response of clinic save API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const loginResponse = (data) => ({
  type: LOGIN_RES,
  payload: data,
});

export const logout = (data) => ({
  type: LOGOUT,
  payload: data,
});

export const logoutResponse = (data) => ({
  type: LOGOUT_RES,
  payload: data,
});
